<template>
  <div>
    <loading v-if="!roomBooking"></loading>
    <div class="container" v-else>
      <div class="card border-0">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Cost</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ roomBooking.total_cost.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Paid</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ roomBooking.total_paid.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Outstanding</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ roomBooking.total_outstanding.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <!--  -->
          <div class="row mb-4">
            <div class="col-md-6 mx-auto">
              <label for="">Choose payment amount</label>
              <select
                v-model="paymentType"
                @change="fetchPaymentIntent"
                class="form-control"
              >
                <option value="">Select payment amount</option>
                <option
                  value="custom"
                  v-if="roomBooking.override_payment_amount > 0"
                  >Custom Amount set by staff - £{{
                    roomBooking.override_payment_amount.toFixed(2)
                  }}</option
                >
                <option value="full"
                  >Full Amount - £{{
                    roomBooking.total_outstanding.toFixed(2)
                  }}</option
                >
                <option value="deposit" v-if="whichDeposit == 'deposit'"
                  >Deposit - £500.00</option
                >
                <option
                  value="deposit_long"
                  v-if="whichDeposit == 'deposit_long'"
                  >Deposit - £1000.00</option
                >
              </select>
            </div>
          </div>

          <!-- Card Details -->
          <div class="row mb-4" v-show="paymentIntent">
            <div class="col-md-6 mx-auto">
              <div class="card border-0 bg-light">
                <div class="card-body">
                  <label for="name" class="mb-3">Card Details</label>
                  <div id="payment-card-element"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- Payment Button -->
          <div class="row mb-4" v-show="paymentIntent">
            <div class="col-auto mx-auto">
              <button
                type="submit"
                class="btn btn-success"
                @click.prevent="processPayment"
                :disabled="busy"
              >
                <span v-if="busy">
                  <i class="fa fa-btn fa-spinner fa-spin"></i> Processing
                  Payment
                </span>

                <span v-else>Make Card Payment</span>
              </button>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-6 mx-auto">
              <p>
                If you have any issues using our online payment system, then you can alternatively make
                a bank transfer to the following details:
              </p>

              <p>
                <div class="card border-0 bg-light">
                  <div class="card-body">
                    Amchara Limited<br/>
                    Monzo Bank<br/>
                    Sort Code: 04-00-04<br/>
                    Account Number: 54817058
                  </div>
                </div>
              </p>
            </div>
          </div>

          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      roomBooking: null,
      name: "",
      busy: false,
      loadingCard: true,
      cardElement: null,
      stripe: null,
      paymentIntent: null,
      paymentType: "",
    };
  },
  computed: {
    whichDeposit() {
      var a = moment();
      var b = moment(this.roomBooking.arrival_date);
      var diff = Math.abs(a.diff(b, "weeks"));

      if (diff > 8) {
        return "deposit";
      }
      return "no_deposit";
    },
  },
  methods: {
    fetchPaymentIntent() {
      return this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/c/room-bookings/" +
            this.roomBooking.id +
            "/payment-intent",
          { type: this.paymentType, client_id: this.$store.user.id }
        )
        .then(({ data }) => {
          this.paymentIntent = data;
          this.initStripe();
        });
    },
    initStripe() {
      this.loading = false;
      this.loadingCard = true;
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      this.cardElement = this.createCardElement("#payment-card-element");
      this.loadingCard = false;
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
              'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);
      return card;
    },
    processPayment() {
      this.busy = true;
      this.stripe
        .confirmCardPayment(this.paymentIntent.client_secret, {
          payment_method: {
            card: this.cardElement,
          },
        })
        .then((result) => {
          if (result.error) {
            console.log(result.error);
            this.$EventBus.$emit("alert", {
              color: "danger",
              message: result.error.message,
            });
            self.hasCardErrors = true;
            this.busy = false;
            return;
          }
          this.$EventBus.$emit("alert", {
            message: "Payment Successful! Thank you.",
          });
          const vm = this;
          setTimeout(() => {
            vm.$router.push("/dashboard");
          }, 2000);
          this.busy = false;
        });
    },
    fetchBooking() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/c/room-bookings/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.roomBooking = data;
        });
    },
  },
  filters: {
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  mounted() {
    this.fetchBooking();
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
